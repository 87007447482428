const currentEnv = process.env.REACT_APP_ENV;

export const baseUrl =
  process.env[`REACT_APP_BLOCKSCOUT_BASE_URL_${currentEnv}`];

// Modules
export const moduleLogs = 'module=logs';

// Actions
export const actionGetLogs = 'action=getLogs';

// Topics
export const transferTopic =
  '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef';

export const userStackedTopic =
  '0x91405db2584f6e8463494f0e18c40f87fe3146322d19c494e8a0e0a039c38869';

export const userUnstackedTopic =
  '0x668647a3bf871c3ff8779af5157eda0c0d6de3f7af4a3468d3ecc571fc413adb';
