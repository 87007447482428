import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { sortBy } from 'lodash';

import { mitToken } from '../../contracts';

import { transfersReducer } from './transfers.reducer';
import { Transfers, FormattedTransfer, TransactionBlockScout } from './types';
import { formatBlockScoutTransfer } from './fetchUtils';

import { provider } from '../../imports/constants';

import { RootState } from '../store';
import {
  actionGetLogs,
  baseUrl,
  moduleLogs,
  transferTopic,
} from '../../explorer/constants';

const mitTokenContract = new ethers.Contract(
  mitToken.contract_address,
  mitToken.abi,
  provider
);

// Define initial state
const transfersInitialState: Transfers = {
  loader: true,
  balance: 0,
  list: [],
  fromBlock: 0,
};

// State slice
export const transfersSlice = createSlice({
  name: 'transfers',
  initialState: transfersInitialState,
  reducers: transfersReducer,
  extraReducers: (builder) => {
    builder.addCase(fetchTransfers.pending, (state) => {
      state.loader = true;
    });

    builder.addCase(fetchTransfers.fulfilled, (state, { payload }) => {
      state.list =
        payload.fromBlock === 0
          ? sortBy(payload.transfers, ['timestamp'])
          : state.list.concat(sortBy(payload.transfers, ['timestamp']));

      state.loader = false;
    });

    builder.addCase(fetchTransfers.rejected, (state) => {
      state.loader = false;
    });

    builder.addCase(fetchBalance.fulfilled, (state, { payload }) => {
      state.balance = payload;
    });
  },
});

// Action creators
export const {
  actions: { setLoader, addTransfer, setFromBlock },
} = transfersSlice;

// Getters
export const getTransfers = (state: RootState): Transfers => state.transfers;

// Async thunks
export const fetchTransfers = createAsyncThunk<
  { address: string; transfers: FormattedTransfer[]; fromBlock: number },
  void
>(
  'transfers/fetchTransfers',
  async (_, { rejectWithValue, getState, dispatch }) => {
    const {
      transfers: { fromBlock },
      user: {
        wallet: { address },
      },
    } = getState() as {
      user: { wallet: { address: string } };
      transfers: { fromBlock: number };
    };

    try {
      let incomingTrx: Array<FormattedTransfer> = [];
      let outcomingTrx: Array<FormattedTransfer> = [];

      let currentIncomingTrx;
      let currentOutcomingTrx;

      do {
        const incomingTransfersResponse = await fetch(
          `${baseUrl}?${moduleLogs}&${actionGetLogs}&fromBlock=${fromBlock}&toBlock=latest&address=${
            mitToken.contract_address
          }&topic0=${transferTopic}&topic1=0x000000000000000000000000${address
            .replace('0x', '')
            .toLowerCase()}&topic0_1_opr=and`
        );

        const incomingTransfersData = await incomingTransfersResponse.json();

        const outcomingTransfersResponse = await fetch(
          `${baseUrl}?${moduleLogs}&${actionGetLogs}&fromBlock=${fromBlock}&toBlock=latest&address=${
            mitToken.contract_address
          }&topic0=${transferTopic}&topic2=0x000000000000000000000000${address
            .replace('0x', '')
            .toLowerCase()}&topic0_2_opr=and`
        );

        const outcomingTransfersData = await outcomingTransfersResponse.json();

        const currentBlock = await provider.getBlockNumber();

        dispatch(setFromBlock(currentBlock + 1));

        const formattedIncomingTransfers = await Promise.all(
          incomingTransfersData.result
            .filter(
              (transfer: TransactionBlockScout) =>
                Number(transfer.logIndex) === 0
            )
            .map(async (transfer: TransactionBlockScout) => {
              const formattedTransfer = await formatBlockScoutTransfer(
                transfer
              );
              return formattedTransfer;
            })
        );

        const formattedOutcomingTransfers = await Promise.all(
          outcomingTransfersData.result
            .filter(
              (transfer: TransactionBlockScout) =>
                Number(transfer.logIndex) === 0
            )
            .map(async (transfer: TransactionBlockScout) => {
              const formattedTransfer = await formatBlockScoutTransfer(
                transfer
              );
              return formattedTransfer;
            })
        );

        currentIncomingTrx = formattedIncomingTransfers;
        currentOutcomingTrx = formattedOutcomingTransfers;

        incomingTrx = [...incomingTrx, ...formattedIncomingTransfers];
        outcomingTrx = [...outcomingTrx, ...formattedOutcomingTransfers];
      } while (
        currentIncomingTrx.length === 1000 ||
        currentOutcomingTrx.length === 1000
      );

      return {
        address,
        fromBlock,
        transfers: [...incomingTrx, ...outcomingTrx],
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchBalance = createAsyncThunk<number, void>(
  'transfers/fetchBalance',
  async (_, { rejectWithValue, getState }) => {
    const {
      user: {
        wallet: { address },
      },
    } = getState() as {
      user: { wallet: { address: string } };
    };

    try {
      const balance = await mitTokenContract.balanceOf(address);

      return Number(ethers.utils.formatEther(balance));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
