import { isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';

import { useYupValidationResolver } from '../../../../../../hooks';
import CloseButton from '../../../../../atoms/CloseButton';
import CustomButton from '../../../../../atoms/Button';
import CustomCheckbox from '../../../../../atoms/Checkbox';
import CustomSelect from '../../../../../atoms/Select';
import {
  annualVolumes,
  foundsProvenance,
  maxUploadedFileSize,
} from '../../../../../../imports/constants';
import { QuestionCircle } from '../../../../../../assets/icons';

interface FormTypes {
  annualVolumes: string;
  fundsProvenance: string;
  economicActivitySector: string;
  economicallyExposedPerson: boolean;
  kyc_bill_proof: string;
}

type Props = {
  nextStep: () => void;
  updateFormData: any;
  formInitialData?: FormTypes;
  disabledForm?: boolean;
};

const TierKyc: React.FC<Props> = ({
  nextStep,
  updateFormData,
  formInitialData,
  disabledForm,
}) => {
  const { t } = useTranslation();
  const [documentFile, setDocumentFile] = useState<File | undefined>(undefined);

  const foundProvenanceOptions: any = [];

  foundsProvenance.forEach((found) => {
    foundProvenanceOptions.push({ label: t(found), id: found });
  });

  const kycDataForm = {
    initialValues: formInitialData,
    validationSchema: Yup.object({
      annualVolumes: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      fundsProvenance: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      economicallyExposedPerson: Yup.boolean(),
      kyc_bill_proof: Yup.mixed().test(
        'FILE_SIZE',
        t('file_upload_error'),
        (value) => value && value.size <= maxUploadedFileSize
      ),
    }),
  };

  const { initialValues, validationSchema } = kycDataForm;

  const resolver = useYupValidationResolver(validationSchema);

  const formState = useForm<FormTypes>({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formState;

  const isError = !isEmpty(errors);

  const inputBillRef = useRef<HTMLInputElement>(null);

  const focusInputBill = () => {
    if (inputBillRef.current) {
      inputBillRef.current.click();
    }
  };

  const onSubmit = (data: any) => {
    updateFormData({
      ...data,
      kyc_bill_proof: documentFile,
    });
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="md:flex md:justify-between md:flex-wrap">
        <div className="w-full">
          <label
            htmlFor="annualVolumes"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('annualVolumes')}
          </label>
          <Controller
            control={control}
            name="annualVolumes"
            render={({ field: { onBlur, onChange, value } }) => (
              <div className="relative mt-2">
                <CustomSelect
                  onBlur={onBlur}
                  onChange={onChange}
                  value={[
                    {
                      label: t('select_placeholder'),
                      id: value,
                    },
                  ]}
                  options={annualVolumes}
                  error={errors.annualVolumes}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.annualVolumes && (
              <p className="text-xs text-error">
                {errors.annualVolumes.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full">
          <label
            htmlFor="fundsProvenance"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('fundsProvenance')}
          </label>
          <Controller
            control={control}
            name="fundsProvenance"
            render={({ field: { onBlur, onChange, value } }) => (
              <div className="relative mt-2">
                <CustomSelect
                  onBlur={onBlur}
                  onChange={onChange}
                  value={[
                    {
                      label: t('select_placeholder'),
                      id: value,
                    },
                  ]}
                  options={foundProvenanceOptions}
                  error={errors.annualVolumes}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.annualVolumes && (
              <p className="text-xs text-error">
                {errors.annualVolumes.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full">
          <Controller
            control={control}
            name="economicallyExposedPerson"
            render={({ field: { onBlur, onChange, value } }) => (
              <div className="relative mt-2">
                <CustomCheckbox
                  onBlur={onBlur}
                  onChange={onChange}
                  disabled={disabledForm}
                  checked={value}
                  error={errors.economicallyExposedPerson}
                  overrides={{
                    Checkmark: {
                      style: () => ({
                        width: '2.5em',
                        height: '2.5em',
                        borderWidth: '0.25rem',
                        borderTopLeftRadius: '0.5rem',
                        borderTopRightRadius: '0.5rem',
                        borderBottomLeftRadius: '0.5rem',
                        borderBottomRightRadius: '0.5rem',
                        backgroundColor: '#D9D9D9',
                        borderColor: `transparent`,
                      }),
                    },
                  }}
                >
                  <div className="flex items-center justify-center">
                    <label
                      htmlFor="economicallyExposedPerson"
                      className="pr-2 text-sm cursor-pointer md:text-base text-gray font-gotham-bold"
                    >
                      <StatefulPopover
                        placement="topRight"
                        content={
                          <div className="max-w-sm p-2 text-center">
                            <strong className="text-center mt-8 font-gotham-bold text-sm text-[#58585A]">
                              {t('exposed_politically_person_description')}
                            </strong>
                          </div>
                        }
                        accessibilityType="tooltip"
                        triggerType={TRIGGER_TYPE.hover}
                      >
                        <div className="flex items-center justify-center">
                          <p className="pr-2">
                            {t('economicallyExposedPerson')}
                          </p>
                          <QuestionCircle />
                        </div>
                      </StatefulPopover>
                    </label>
                  </div>
                </CustomCheckbox>
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.economicallyExposedPerson && (
              <p className="text-xs text-error">
                {errors.economicallyExposedPerson.message}
              </p>
            )}
          </div>
        </div>
        {!disabledForm && (
          <div className="w-full">
            <label
              htmlFor="kyc_bill_proof"
              className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
            >
              {t('kyc_bill_proof')}
              <p className="text-sm text-gray">
                {t('bill_description_upload')}
              </p>
            </label>

            <div className="mt-2">
              {!documentFile ? (
                <Controller
                  name="kyc_bill_proof"
                  control={control}
                  render={({ field: { name, onChange } }) => (
                    <div className="flex">
                      <input
                        name={name}
                        type="file"
                        accept="image/*"
                        hidden
                        ref={inputBillRef}
                        onChange={async () => {
                          if (inputBillRef.current?.files) {
                            const file = inputBillRef.current.files[0];

                            setDocumentFile(file);
                            onChange(file);
                          }
                        }}
                      />
                      <button
                        type="button"
                        className="text-[#B5B5B5] font-gotham-bold justify-start pl-2"
                        onClick={focusInputBill}
                      >
                        {t('click_to_upload')}
                      </button>
                    </div>
                  )}
                />
              ) : (
                <div className="grid w-48 grid-cols-2 pl-2">
                  <p className="flex items-center font-gotham-bold text-[#B5B5B5]">
                    {documentFile.name}
                  </p>
                  <CloseButton handleClose={() => setDocumentFile(undefined)} />
                </div>
              )}
            </div>

            <div className="min-h-[2rem] mt-2">
              {errors.kyc_bill_proof && (
                <p className="text-xs text-error">
                  {errors.kyc_bill_proof.message}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      {!disabledForm && (
        <div className="max-w-sm mx-auto">
          <CustomButton
            label={t('removeLimit')}
            disabled={isError}
            type="submit"
          />
        </div>
      )}
    </form>
  );
};

TierKyc.defaultProps = {
  disabledForm: false,
  formInitialData: {
    economicActivitySector: '',
    annualVolumes: '',
    fundsProvenance: '',
    economicallyExposedPerson: false,
    kyc_bill_proof: '',
  },
};

export default TierKyc;
