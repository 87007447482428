import { encrypt, decrypt } from 'crypto-js/aes';
import { enc } from 'crypto-js';

import { setWallet, updateRole } from '../../store/user/user.slice';

import { useValidateOTPRequestApiMutation } from '../../services/firestoreRestApi';

import { useAppDispatch } from '../utils/reduxUtils';
import { application } from '../../imports/constants';

import { setFromBlock } from '../../store/transfers/transfers.slice';

const useLoginUser = () => {
  const dispatch = useAppDispatch();
  const [validateOtpSession] = useValidateOTPRequestApiMutation();

  const logInUser = async (values: any) => {
    const { verificationCode, wallet, email, password } = values;

    const result = await validateOtpSession({
      code: verificationCode,
    });

    if ('error' in result) {
      return false;
    }

    dispatch(setFromBlock(0));

    const walletFromLocalStorage = localStorage.getItem('wallets');
    let formattedWallets;

    if (walletFromLocalStorage) {
      formattedWallets = JSON.parse(walletFromLocalStorage);
      if (!formattedWallets[email]) {
        const { address, privateKey, mnemonic } = wallet;

        const walletToSet = {
          address,
          privateKey,
          mnemonic: mnemonic.phrase,
        };

        const encryptedWallet = encrypt(
          JSON.stringify(walletToSet),
          password
        ).toString();

        formattedWallets[email] = encryptedWallet;
      }
    } else {
      const { address, privateKey, mnemonic } = wallet;

      const walletToSet = {
        address,
        privateKey,
        mnemonic: mnemonic.phrase,
      };

      const encryptedWallet = encrypt(
        JSON.stringify(walletToSet),
        password
      ).toString();

      formattedWallets = { [email]: encryptedWallet };
    }

    localStorage.setItem('wallets', JSON.stringify(formattedWallets));

    const bytes = decrypt(formattedWallets[email], password);
    const decryptedWallet = JSON.parse(bytes.toString(enc.Utf8));
    dispatch(setWallet(decryptedWallet));
    dispatch(updateRole(application));

    return true;
  };

  return { logInUser };
};

export default useLoginUser;
