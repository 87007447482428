import { ethers } from 'ethers';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { toast } from 'react-toastify';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useCallbackState } from '..';
import { useSendEmailNotificationApiMutation } from '../../services/firestoreRestApi';

import { provider } from '../../imports/constants';
import { UserState } from '../../store/user/types';

import { mitToken, vipStatus } from '../../contracts';
import {
  actionGetLogs,
  baseUrl,
  moduleLogs,
  userStackedTopic,
  userUnstackedTopic,
} from '../../explorer/constants';
import { TransactionBlockScout } from '../../store/transfers/types';

const mitTokenContract = new ethers.Contract(
  mitToken.contract_address,
  mitToken.abi,
  provider
);

const vipStatusContract = new ethers.Contract(
  vipStatus.contract_address,
  vipStatus.abi,
  provider
);

const overrides = {
  gasLimit: 4000000,
  gasPrice: 0,
};

type VipLevel = {
  level: number;
  name: string;
  threshold: number;
};

export type LockEvents = {
  hash: string;
  lockDate: string;
  lockTimestamp: number;
  vipLevel: number | undefined;
  lockedAmount: number;
  unlockDate: string;
  unlockTimestamp: number;
};

type UnlockEvents = {
  hash: string;
  vipLevel: number;
  unlockDate: string;
  unlockTimestamp: number;
};

type LockingEvents = {
  fromBlock: number;
  lock: LockEvents[];
  unlock: UnlockEvents[];
};

type LockInfo = {
  lockDate: number;
  amount: number;
};

type InitializationValues = {
  lockInfo: LockInfo;
  vipLevels: VipLevel[];
  lockingPeriod: number;
};

const defaultInitializationValues = {
  lockInfo: {
    lockDate: 0,
    amount: 0,
  },
  vipLevels: [],
  lockingPeriod: 0,
};

const UseLockMit = () => {
  const { t } = useTranslation();
  const [sendEmailNotification] = useSendEmailNotificationApiMutation();

  const [txnsLoader, setTxnsLoader] = useState(true);
  const [lockLoader, setLockLoader] = useState(true);

  const [initializationValues, setInitializationValues] =
    useCallbackState<InitializationValues>(defaultInitializationValues);

  const {
    lockInfo: { amount },
    vipLevels,
    lockingPeriod,
  } = initializationValues;

  const [currentVipLevel, setCurrentVipLevel] = useState(0);
  const [isUnlockable, setIsUnlockable] = useState(false);
  const [lockingEvents, setLockingEvents] = useState<LockingEvents | null>(
    null
  );

  const user = useSelector(({ user }: { user: UserState }) => user);

  const {
    wallet: { privateKey, address },
  } = user;

  const signerWallet = new ethers.Wallet(privateKey, provider);
  const contractWithSigner = mitTokenContract.connect(signerWallet);

  // Define events filters
  const lockEvents = mitTokenContract.filters.UserStaked(address);
  const unlockEvents = mitTokenContract.filters.UserUnstaked(address);

  // Get locking period from the blockchain
  const getLockingPeriod = async () => {
    const lockingPeriod = await mitTokenContract.getLockingPeriod();
    const formattedLockingPeriod = lockingPeriod.toNumber();
    return formattedLockingPeriod;
  };

  // Get vip levels from the blockchain
  const getVipLevels = async () => {
    const vipLevels = await vipStatusContract.getVipLevels();
    const formattedVipLevels = vipLevels.map(
      (vipLevel: VipLevel, i: number) => {
        const threshold = parseInt(
          ethers.utils.formatEther(vipLevel.threshold.toString()),
          10
        );
        return {
          level: i,
          name: vipLevel.name,
          threshold,
        };
      }
    );
    formattedVipLevels.shift();
    return formattedVipLevels;
  };

  // Get current locked amount from the blockchain
  const getLockInfo = async () => {
    const lockInfo = await mitTokenContract.stakes(address);
    const formattedLockInfo = {
      lockDate: lockInfo.stakeDate.toNumber(),
      amount: parseInt(ethers.utils.formatEther(lockInfo.value.toString()), 10),
    };
    return formattedLockInfo;
  };

  // Function to calculate current vip level
  const calculateCurrentVipLevel = (
    vipLevels: VipLevel[],
    stakedAmount: number
  ) => {
    const currentLevel =
      vipLevels.find((vipLevel) => vipLevel.threshold === stakedAmount)
        ?.level || 0;

    return currentLevel;
  };

  // Function to check if user can perform the unlock
  const checkIsUnlockable = (lockInfo: LockInfo, lockingPeriod: number) => {
    const currentTimeStamp = Math.floor(Date.now() / 1000);
    const lastLockExpiryTimestamp = lockInfo.lockDate + lockingPeriod;

    let isUnlockable = false;
    if (currentTimeStamp > lastLockExpiryTimestamp && lockInfo.amount !== 0) {
      isUnlockable = true;
    }

    return isUnlockable;
  };

  // Get all the locking and unlocking events from the blockchain
  const getLockingEvents = async (
    vipLevels: VipLevel[],
    lockingPeriod: number
  ) => {
    setTxnsLoader(true);

    // const cachedEvents = localStorage.getItem('lockingEvents');
    // if (cachedEvents) {
    //   setLockingEvents(JSON.parse(cachedEvents));
    //   setTxnsLoader(false);
    // }

    const lockTransactionResponse = await fetch(
      `${baseUrl}?${moduleLogs}&${actionGetLogs}&fromBlock=0&toBlock=latest&address=${
        mitToken.contract_address
      }&topic0=${userStackedTopic}&topic1=0x000000000000000000000000${address
        .replace('0x', '')
        .toLowerCase()}&topic0_1_opr=and`
    );

    const lockTransactionData = await lockTransactionResponse.json();

    const unlockTransactionResponse = await fetch(
      `${baseUrl}?${moduleLogs}&${actionGetLogs}&fromBlock=0&toBlock=latest&address=${
        mitToken.contract_address
      }&topic0=${userUnstackedTopic}&topic1=0x000000000000000000000000${address
        .replace('0x', '')
        .toLowerCase()}&topic0_1_opr=and`
    );

    const unlockTransactionData = await unlockTransactionResponse.json();

    const formattedLockTransactions = lockTransactionData.result.map(
      (lockTxn: TransactionBlockScout) => {
        const lockDate = Number(lockTxn.timeStamp);

        const result = ethers.utils.defaultAbiCoder.decode(
          ['uint256', 'uint256'],
          ethers.utils.hexDataSlice(lockTxn.data, 0)
        );

        const lockedAmount = Number(
          ethers.utils.formatEther(result[1]).toString()
        );

        return {
          hash: lockTxn.transactionHash,
          lockDate: moment(new Date(lockDate * 1000)).format('DD-MM-YYYY'),
          lockedAmount,
          lockTimestamp: lockDate,
          vipLevel: vipLevels.find(
            (vipLevel) => vipLevel.threshold === lockedAmount
          )?.level,
          unlockDate: moment(
            new Date((lockDate + lockingPeriod) * 1000)
          ).format('DD-MM-YYYY'),
          unlockTimestamp: lockDate + lockingPeriod,
        };
      }
    );

    const formattedUnlockTransactions = await Promise.all(
      unlockTransactionData.result.map((lockTxn: TransactionBlockScout) => ({
        hash: lockTxn.transactionHash,
        vipLevel: 0,
        unlockDate: moment(new Date(Number(lockTxn.timeStamp) * 1000)).format(
          'DD-MM-YYYY'
        ),
        unlockTimestamp: Number(lockTxn.timeStamp),
      }))
    );

    // Calculate data to save in the browser cache
    const eventsToCache = {
      lock: formattedLockTransactions,
      unlock: formattedUnlockTransactions,
      fromBlock: 0,
    };

    setTxnsLoader(false);

    return eventsToCache;
  };

  // Function to initialize the hook's state
  const initialize = async () => {
    setLockLoader(true);
    const initializationValues = await Promise.all([
      getVipLevels(),
      getLockingPeriod(),
      getLockInfo(),
    ]);

    setInitializationValues(
      {
        vipLevels: initializationValues[0],
        lockingPeriod: initializationValues[1],
        lockInfo: initializationValues[2],
      },
      async (newInitializationValues) => {
        const isUnlockable = checkIsUnlockable(
          newInitializationValues.lockInfo,
          newInitializationValues.lockingPeriod
        );
        setIsUnlockable(isUnlockable);

        const currentLevel = calculateCurrentVipLevel(
          newInitializationValues.vipLevels,
          newInitializationValues.lockInfo.amount
        );
        setCurrentVipLevel(currentLevel);

        const lockingEvents = await getLockingEvents(
          newInitializationValues.vipLevels,
          newInitializationValues.lockingPeriod
        );
        setLockingEvents(lockingEvents);
      }
    );

    setLockLoader(false);
  };

  const notifyNewLevel = async (lockedAmount: number) => {
    const currentPath = window.location.pathname;
    const currentPathLng = currentPath.split('/')[1];

    const vipLevels = await getVipLevels();
    const reachedVipLevel = calculateCurrentVipLevel(vipLevels, lockedAmount);

    const data = { reachedVipLevel, lockedAmount };

    sendEmailNotification({
      notificationType: `reachedNewLevel_${currentPathLng}`,
      data,
    });
  };

  useEffect(() => {
    initialize();

    // Events listeners
    mitTokenContract.on(lockEvents, (address, stakeDate, totalStake, event) => {
      // initialize();
      toast.success(t('lock_tokens.lock_success'));

      const lockedAmount = parseInt(
        ethers.utils.formatEther(totalStake.toString()),
        10
      );

      notifyNewLevel(lockedAmount);
    });

    mitTokenContract.on(unlockEvents, () => {
      // initialize();
      toast.success(t('lock_tokens.unlock_success'));
    });

    return () => {
      mitTokenContract.removeAllListeners();
    };
  }, []);

  // Function to lock tokens
  const lock = async (amount: string) => {
    setLockLoader(true);

    const formattedAmount = ethers.utils.parseUnits(amount).toString();
    const txn = await contractWithSigner.lock(formattedAmount, overrides);

    try {
      await txn.wait();
    } catch (error) {
      toast.error(t('lock_tokens.failed'));
    }

    // initialize();

    setTimeout(initialize, 5000);

    setLockLoader(false);
  };

  // Function to unlock tokens
  const unlock = async () => {
    setLockLoader(true);

    const txn = await contractWithSigner.unlock(overrides);

    try {
      await txn.wait();
    } catch (error: any) {
      toast.error(t('lock_tokens.failed'));
    }

    // initialize();

    setTimeout(initialize, 5000);

    setLockLoader(false);
  };

  return {
    lock,
    unlock,
    lockingEvents,
    loader: lockLoader || txnsLoader,
    lockedAmount: amount,
    vipLevels,
    lockingPeriod,
    isUnlockable,
    currentVipLevel,
  };
};

export default UseLockMit;
